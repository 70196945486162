import { STORAGE_LOCAL } from 'constants/enums';
import { STORAGE_URL } from 'config/app-config';

export function normalizeText(text: string) {
    let result = text.replace(/^\s+|\s+$/g, '').toLowerCase();
    const from = 'ãàáäâèéëêìíïîõòóöôùúüûç·/_,:;';
    const to = 'aaaaaeeeeiiiiooooouuuuc------';
    for (let i = 0; i < from.length; i++) {
        result = result.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }
    return result
        .replace(/[^a-z0-9ñ -]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-');
}

export function getFileName(title: string, subtitle?: string) {
    const primaryTitle = `${normalizeText(title)}_`;
    const secondaryTitle = subtitle ? `${normalizeText(subtitle)}_` : '';
    const date = Date.now();
    const filename = `${primaryTitle}${secondaryTitle}${date}`;
    return filename;
}

export const formatearTexto = (texto: string): string[] => {
    const [dias, nombres] = texto.split(' | ');
    const d = dias.trim();
    let r = "";
    let t = "";
    if(nombres){
        const [rpa, tes] = nombres.split('TES:').map(line => line.trim());
        r = rpa.trim();
        if(tes) t = tes.trim();
    }
    return [d, r, `TES: ${t}`];
};

export  const getAvatarURL = (avatar: string) => avatar ? avatar.endsWith('.jpg') ?
                                                `${STORAGE_URL}/${avatar}`
                                                : `${STORAGE_LOCAL}/${avatar}`:`${STORAGE_LOCAL}/avatar_default.png`;

