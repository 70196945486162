import React, { ReactElement, useState } from 'react';
import { ProcesoTableModel } from './ProcesoTable';
//mui
import { Box, CircularProgress, Card, CardActions, CardContent, IconButton, Stack, Typography, Button } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import { ESTADO_D } from 'constants/colors';

type Props = {
    data: ProcesoTableModel;
    loading: string;
    refresh: () => void;
    onViewClick?: () => void;
    onDetalleClick?: () => void;
    onImprimirClick: () => void;
};

export const ProcesoTableItem = ({ data, refresh, onViewClick, onDetalleClick, onImprimirClick, loading }: Props): ReactElement => {

    const renderDetalleButton = () => {
        return (
            <Button variant="outlined" size="small" onClick={() => (onDetalleClick ? onDetalleClick() : false)} color="info" endIcon={<ListAltIcon />}>
                Detalle
          </Button>
        );
    };

    const renderImprimirButton = () => {
        return (
            <Button variant="outlined" disabled={loading===data.id} size="small" onClick={() => (onImprimirClick ? onImprimirClick() : false)} color="error" endIcon={loading===data.id ? <CircularProgress size={16}  />:<PictureAsPdfIcon />}>
                Reporte
            </Button>
        );
    };

    /* const renderViewState = () => {
        const estado = data.estado;
        const background = ESTADO_D[estado];
        return (
            <Typography
                align="center"
                variant="body2"
                sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'right' }}
            >
                <IconButton size="small">
                    <CheckCircleIcon style={{ fontSize: '14px', color: background }} />
                </IconButton>
            </Typography>
        );
    }; */

    return (
        <Card variant="elevation" sx={{ backgroundColor: 'primary.lighter', mb: 2 }}>
            <CardContent sx={{  p: 3, pb:0, pr: 1, '&:last-child': { pb: 0 } }}>
                <Stack direction="row" spacing={2}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body2" display="block" sx={{  textAlign: 'left', mb: 0.5, lineHeight: 1.125, fontSize: '12px', color: 'primary.dark', fontWeight: 'bold'}}>
                            {data.objeto_contratacion}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{  textAlign: 'left', mb: 2 }}>
                            {data.modalidad_descripcion}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{  textAlign: 'left' }}>
                            <b>Fecha de Registro:&nbsp;</b>{data.fecha_registro}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{   }}>
                            <b>Hoja Ruta:&nbsp;</b>{data.hoja_ruta}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ mb: 2 }}>
                            <b>Cod. Int.:&nbsp;</b>{data.codigo_interno_entidad}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{  textAlign: 'left' }}>
                            {data.solicitante}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{  textAlign: 'left', fontWeight: 'bold', mt:1, lineHeight: 1.125 }}>
                            {data.area}
                        </Typography>
                    </Box>
                    {/* <Box sx={{ flexShrink: 0 }}>
                        {renderViewState()}
                    </Box> */}
                </Stack>
            </CardContent>
            <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {renderImprimirButton()}
                {renderDetalleButton()}
            </CardActions>
        </Card>
    );
};
