import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { SelectOption, FormGroup, FormDialog, FormValue } from 'components/core/FormDialog';
//import { DetalleDestinoModuleServi } from '../DetalleDestinoModuleService';
import { useNotify } from 'services/notify';
import { useIsMounted } from 'hooks/useIsMounted';
import { parse as dateParse } from 'date-fns';

import {  ENUM_CIUDAD, ENUM_DEPARTAMENTOS, ENUM_PROVINCIAS, ENUM_TIPO_TRANSPORTE_OP, TIME_FORMAT } from 'constants/enums';
import { OptionsFormModel, OptionsFormModelFecha } from 'modules/Types';
import { dateTest } from 'components/core/FormDialog/yup-tests';
import { MemorandumModuleService } from 'modules/viatico/memorandum/MemorandumModuleService';
import { SelectOptionFecha } from 'components/core/FormDialog/Types';

export type MemorandumDetalleFormModel = {
    id?                     : string;
    tipo_vehiculo_op        : string;
    objetivo_viaje          : string;
    destino_reg             : string;
    fecha_dia               : Date;
    hora_inicio             : string;
    hora_fin                : string;
    pasaje_ida              : number;
    pasaje_retorno          : number;
    total_pasaje_dia        : number;
    tipo_vehiculo_opvida        : string;
    tipo_vehiculo_opvuelta        : string;
    estado                    : string;
    memorandum_id?          : string | null;
    viatico_id?             : string | null;
    vehiculo_id?            : string | null;
    destino_id?             : string | null;
    destino_id2?             : string | null;
    //campos de EscalaDestino para llenar los datos de destino mas facilmente
    tipo_pcp? :string;
    provincia? : string;

    _createdBy?: string;
    _createdAt?: string;
    _updatedBy?: string;
    _updatedAt?: string;
};

export type MemorandumFormModelDetalle = {
    id?                    : string;
    cod_depart_memo        : string;
    fecha_inicio_viaje     : Date;
    fecha_fin_viaje        : Date;
    cantidad_dias          : number;
};

export type MemorandumFormModelDetalle2 = {
    id?                    : string;
    nombre_usuario : string;
    ci: string;
    cargo_usuario : string
    tipo_comision_idp      : string;
    fecha_inicio_viaje     : string;
    fecha_fin_viaje        : string;
    cantidad_dias          : string;
};
export type MemorandumTipoPCPModel = {
    id: string;
    nombre: string;
    caption?: string;
};

export type MemorandumConteoDiasModel = {
    id: string;
    nombre: boolean;
    caption?: number;
};

type Props = {
    memorandumId  : string;
    open: boolean;
    formModel?: MemorandumDetalleFormModel;
    onComplete: () => void;
};

export const MemorandumDetalleFormDialog = ({ open, formModel, memorandumId, onComplete }: Props): ReactElement => {
    const notify = useNotify();
    const isMounted = useIsMounted();

    const isValid = (formModel?.vehiculo_id?.length ?? 0) > 0;
    const [option, setOption] = useState<boolean>(isValid);

    const vehiculoOPOptions: SelectOption[] = ENUM_TIPO_TRANSPORTE_OP;
    const [showTipoVehiculo, setTipoVehiculo] = useState<string>('');
    const [vehiculos, setVehiculos] = useState<OptionsFormModel[]>([]);
    const [tipoPCP, setTipoPCP] = useState<string>('');
    const provinciasOptions: SelectOption[] = ENUM_PROVINCIAS;
    const departamentosOptions: SelectOption[] = ENUM_DEPARTAMENTOS;
    const [paises, setPaises] = useState<OptionsFormModel[]>([]);
    const [comunidades, setComunidades] = useState<OptionsFormModel[]>([]);
    const [fechas, setFechas] = useState<OptionsFormModelFecha[]>([]);

    const vehiculosOptions: SelectOption[] = vehiculos.map((item: OptionsFormModel) => ({ value: item.id || '', label: item.nombre }));
    const paisesOptions: SelectOption[] = paises.map((item: OptionsFormModel) => ({ value: item.id || '', label: item.nombre }));
    const comunidadesOptions: SelectOption[] = comunidades.map((item: OptionsFormModel) => ({ value: item.id || '', label: item.nombre }));
    const fechasOptions: SelectOptionFecha[] = fechas.map((item: OptionsFormModelFecha) => ({ value: item.id || '', label: item.nombre }));

    const formLayout: FormGroup<MemorandumDetalleFormModel>[] = [

        {
            title: 'Datos del Vehiculo',
            grid: [
                [   { label: 'Tipo de Comision es:  ' , type: 'label' },
                    { name: 'tipo_pcp', label: tipoPCP , type: 'text', disabled:true }
                ],
                [
                    {
                        name: 'tipo_vehiculo_op',
                        label: 'Tipo de vehiculo Oficial o Publico',
                        type: 'select',
                        options: vehiculoOPOptions,
                        onChange: (value, formik) => {
                            formik.setFieldValue('tipo_vehiculo_op', value);
                            setTipoVehiculo(String(value));
                        },
                    }, ],
                    [
                    (showTipoVehiculo ==='OFICIAL')?
                        { name: 'vehiculo_id', label: 'Placa del vehiculo ', type: 'autocomplete', options: vehiculosOptions }
                        :{ type: 'empty'},
                    ],

            ]
        },
        {
            title: 'Datos del Viaje',
            grid: [
                [{ name: 'fecha_dia', label: 'Fecha registro por dia', type: 'select', options: fechasOptions}   ],
                [{ name: 'objetivo_viaje', label: 'Describa el objetivo de viaje', type: 'textarea' ,infoText: 'Descripcion del viaje'}], //type: 'autocomplete', options: areasOptions

                [   (tipoPCP ==='INTERNACIONAL')?
                    { name:'destino_reg', label: 'Ingrese Pais de Destino', type: 'select', options: paisesOptions }: //seleccion
                    (tipoPCP ==='NACIONAL')?
                    { name:'destino_reg', label: 'Seleccione el Departamento', type: 'select', options: departamentosOptions }:
                    { name:'provincia', label: 'Seleccione el Provincia', type: 'select', options: provinciasOptions},
                ],
                [   (tipoPCP ==='PROVINCIAL')?
                    {name:'destino_reg', label: 'Seleccione Comunidad',  type: 'select', options: comunidadesOptions,
                     }:{type:'empty'},
                ],


               // [{ name: 'destino_reg', label: 'Destino de Viaje', type: 'text', infoText: 'ej. Challapata' }], //type: 'autocomplete', options: areasOptions
                [
                 { name: 'hora_inicio', label: 'Hora inicio', type: 'time', infoText: 'HH:MM', format: TIME_FORMAT},
                 { name: 'hora_fin', label: 'Hora fin', type: 'time', infoText: 'HH:MM', format: TIME_FORMAT } // type: 'radio-group', options: padreOptions, inlineDisplay: true
                ],

                /*[
                    { name: 'memorandum_id', label:memorandumId , type: 'label', disabled:true  },//hidden:true
                ]*/

            ]
        }
    ];

    const isDateUnique = async (nro: Date, memorandum_id:string) => {
        const formModelDate = formModel && formModel.fecha_dia ? new Date(formModel.fecha_dia) : new Date();

        if(formModelDate && formModelDate.getTime() !== nro.getTime()){
           /*  const result = await DetalleDestinoModuleService.getFechaData(nro, memorandum_id);
            const exists = Boolean(result.data.nro);
            return !exists; */
        }
        return true;
    };


    const validationSchema = yup
        .object({
            tipo_vehiculo_op        : yup.string().required(),
            objetivo_viaje          : yup.string().required(),
            destino_reg             : yup.string().required(),
            fecha_dia               : yup.date().required().test('is-unique', 'La fecha Ingresada se encuentra ya registrado',
                 async (value) => {
                if (value !== undefined) {
                    return await isDateUnique(value, memorandumId);
                    }
                    return true;
                }),
            hora_inicio             : yup.string().required().test(dateTest(TIME_FORMAT)),
            hora_fin                : yup.string().required().test(dateTest(TIME_FORMAT)),
        })
        .defined();

    const handleSubmit = async (formData: FormValue) => {
        /* const result = await DetalleDestinoModuleService.createOrUpdateDetalleDestino(formData as unknown as MemorandumDetalleFormModel);
        if (!result.success) return notify.error(result.msg);
        notify.success(result.msg); */
        return onComplete();

    };

    const handleCancel = () => {
        onComplete();
    };

    //enviamos el id de memorandum al cargar valores
    const zeroValues: MemorandumDetalleFormModel = {
        tipo_vehiculo_op        : '',
        objetivo_viaje          : '',
        destino_reg             : '',
        fecha_dia               : new Date(),
        hora_inicio             : '',
        hora_fin                : '',
        pasaje_ida              : 0,
        pasaje_retorno          : 0,
        total_pasaje_dia        : 0,
        tipo_vehiculo_opvida    : '',
        tipo_vehiculo_opvuelta  : '',
        estado                  : 'PENDIENTE',
        memorandum_id           : memorandumId,
        tipo_pcp                : tipoPCP,
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            const resultVehiculo = await MemorandumModuleService.getAllVehiculos({ id: isValid || option });
            if (!resultVehiculo || !resultVehiculo.success) return;
            const newVehiculo = resultVehiculo.rows || [];
            //Memorandum
            const resultMemorandum = await MemorandumModuleService.getTipoPCP(memorandumId);
            if (!resultMemorandum || !resultMemorandum.success) return;
            const nombre = String(resultMemorandum.data?.nombre);
            // newMemorandum.push({id, nombre});
            //Listado de paises
            const resultPaises = await MemorandumModuleService.getAllPaises();
            if (!resultPaises || !resultPaises.success) return;
            const newPaises = resultPaises.rows || [];
            // listado de comunidades
            const resultComunidades = await MemorandumModuleService.getAllComunidades();
            if (!resultComunidades || !resultComunidades.success) return;
            const newComunidades = resultComunidades.rows || [];

              // listado de fechas
              const resultListaFechas = await MemorandumModuleService.getRangoFechas(memorandumId);
              if (!resultListaFechas || !resultListaFechas.success) return;
              const newListaFechas = resultListaFechas.rows || [];

        if (isMounted())
           setVehiculos(newVehiculo);
           setTipoPCP(nombre);
           setPaises(newPaises);
           setComunidades(newComunidades);
           setFechas(newListaFechas);
        };
        if (open) {
            fetchData();
        }
    }, [open, isMounted, formModel]);


    const newFormModel = formModel && {
        id                    : formModel.id,
        tipo_vehiculo_op      : formModel.tipo_vehiculo_op,
        objetivo_viaje        : formModel.objetivo_viaje,
        destino_reg           : formModel.destino_reg,
        fecha_dia             : formModel.fecha_dia,
        hora_inicio           : dateParse(formModel.hora_inicio, TIME_FORMAT, new Date()),
        hora_fin              : dateParse(formModel.hora_fin, TIME_FORMAT, new Date()),
        pasaje_ida            : formModel.pasaje_ida,
        pasaje_retorno        : formModel.pasaje_retorno,
        total_pasaje_dia      : formModel.total_pasaje_dia,
        tipo_vehiculo_opvida  : formModel.tipo_vehiculo_opvida,
        tipo_vehiculo_opvuelta: formModel.tipo_vehiculo_opvuelta,
        estado                : formModel.estado,
        memorandum_id         : formModel.memorandum_id,
        viatico_id            : formModel.viatico_id,
        vehiculo_id           : formModel.vehiculo_id,
        destino_id            : formModel.destino_id,
        destino_id2           : formModel.destino_id2,
    };

    return (
        <FormDialog
            addTitle="Agregar Memorandum Detalle"
            editTitle="Editar Memorandum Detalle"
            open={open}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={newFormModel || zeroValues}
            formLayout={formLayout}
            validationSchema={validationSchema}
            //debug
            isEdit={typeof formModel !== 'undefined'}
        />
    );
};
