import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, StatusColumn, ActionColumn, ChangeStateColumn, OnUpdateOptions, DataTableRefProps, ActiveColumn } from 'components/core/DataTable';
//@mui
//import { Box } from '@mui/material';
import { Box, Dialog, DialogContent, Paper, Typography } from '@mui/material';
//services
import { QueryParams } from 'services/base/Types';
import { useNotify } from 'services/notify';
//model

//hooks
import { useIsMounted } from 'hooks/useIsMounted';
import { BACKGROUND_1, ESTADO_A } from 'constants/colors';
import { STORAGE_URL } from 'config/app-config';
import { STORAGE_LOCAL } from 'constants/enums';

import { MemorandumFormModel } from 'modules/viatico/memorandum/components/MemorandumFormDialog';
import { MemorandumModuleService } from '../MemorandumModuleService';

export type MemorandumDetalleTableModel = {
    id                       : string;
    tipo_vehiculo_op         : string;
    objetivo_viaje           : string;
    destino_reg              : string;
    fecha_dia                : Date;
    hora_inicio              : string;
    hora_fin                 : string;
    pasaje_ida               : number;
    pasaje_retorno           : number;
    total_pasaje_dia         : number;
    tipo_vehiculo_opvida     : string;
    tipo_vehiculo_opvuelta   : string;
    estado                    : string;
    memorandum_id?            : string;
    viatico_id?               : string;
   // vehiculo_id              : string;
    destino_id?               : string;
    destino_id2?               : string;
    activo                   : boolean;
   // aumentamos campos vehiculo
     vehiculo_id? : string;
     num_placa? : string;
     cod_memorandum? :string;
    // para las columnas especiales
    actions: unknown;
};

export type DetalleDestinoTableModel = {
    id: string;
    tipo_vehiculo_op         : string;
    objetivo_viaje           : string;
    destino_reg              : string;
    fecha_dia                : Date;
    hora_inicio              : string;
    hora_fin                 : string;
    pasaje_ida               : number;
    pasaje_retorno           : number;
    total_pasaje_dia         : number;
    tipo_vehiculo_opvida     : string;
    tipo_vehiculo_opvuelta   : string;
    estado                   : string;
    activo                   : boolean;

    // para las columnas especiales
    actions: unknown;
    // aumentamos campos vehiculo
    vehiculo_id? : string;
    num_placa? : string;
};


export type MemorandumDetalleTableRefProps = {
    refresh: (updateParams?: UpdateParams<MemorandumDetalleTableModel>) => void;
    getQueryParams: () => QueryParams;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<MemorandumDetalleTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

const FORMAT = 'dd/MM/yyyy';

const nombresFilter: HeaderFilter = { type: 'text' };
const nombrePadreFilter: HeaderFilter = { type: 'text' };
const partidaFilter: HeaderFilter = { type: 'text' };


type Props = {
    memorandumId  : string;
    onAddClick: () => void;
    onViewClick: (idDetalleDestino: string) => Promise<void>;
    onEditClick: (idDetalleDestino: string) => Promise<void>;
    data: MemorandumFormModel | null;
};


export const MemorandumDetalleTableComponent = (props: Props,ref: React.Ref<MemorandumDetalleTableRefProps>): ReactElement => {
    const { onViewClick, onAddClick, onEditClick, memorandumId, data} = props;
    const notify = useNotify();
    const [avatar, setAvatar] = useState("");
    const isMounted = useIsMounted();
    const getAvatarURL = (avatar: string) => avatar ? avatar.endsWith('.jpg') ?
                                                 `${STORAGE_URL}/${avatar}`
                                                : `${STORAGE_LOCAL}/${avatar}`:`${STORAGE_LOCAL}/avatar_default.png`;

    const [tableParams, setTableParams] = useState<UpdateParams<MemorandumDetalleTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<MemorandumDetalleTableModel>[] = [
        { id: 'actions', label: 'Acciones', sort: false, render:renderColumnActions },
        { id: 'cod_memorandum', label: 'Codigo de Memorandum', align: 'center',filter: partidaFilter},
        { id: 'tipo_vehiculo_op', label: 'Vehiculo Oficial o Publico',width:200 , align: 'center', filter: partidaFilter},
        { id: 'vehiculo_id', label: 'Tipo de Vehiculo', align: 'center',width:150 ,filter: partidaFilter},
        { id: 'num_placa', label: 'Placa Vehiculo', align: 'center', filter: partidaFilter},
        { id: 'objetivo_viaje', label: 'Objetivo de Viaje', align: 'center',  width: 240 },
        { id: 'destino_reg', label: 'Destino registrado', align: 'center',filter: partidaFilter },
        { id: 'fecha_dia', label: 'Fecha dia de viaje', align: 'center',width:200,  filter: partidaFilter },
        { id: 'hora_inicio', label: 'Hora Inicio', align: 'center'},
        { id: 'hora_fin', label: 'Hora Fin', align: 'center' },
    ];

    const handleUpdateTable = (params: UpdateParams<MemorandumDetalleTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        const newParams = {...params,
            filters: { ...params.filters, memorandum_id: memorandumId },};
            MemorandumModuleService.getTableDetalleDestino(newParams as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<MemorandumDetalleTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    function renderColumnActions(data: MemorandumDetalleTableModel): ReactElement {
        return (
            <ActionColumn
                onViewClick={() => onViewClick(data.id)}
                onEditClick={() => onEditClick(data.id)}
                deleteMessage={
                    <div>
                        <div>¿Quiere eliminar el registro?</div>
                        <br />
                        <div>
                            {/*data. sigla   y data. nombre  <strong>Nombre: </strong> {data.fecha_dia +' - '+data.id}  cambiar por una id mas representativa*/}
                            <strong>Nombre: </strong> {data.destino_reg}
                        </div>
                    </div>
                }
                onDeleteClick={async () => {
                    return MemorandumModuleService.destroyDetalleDestino(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('DetalleDestino eliminado exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    const [open, setOpen] = useState(false);

    const handleImageClick = (avatar: string) => {
        setAvatar(avatar)
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    type RenderViewDialogProps = {
        open: boolean;
        onClose: () => void;
        imageUrl: string;
    };

    const RenderViewDialog: React.FC<RenderViewDialogProps> = ({ open, onClose, imageUrl }) => {
        return (
          <Dialog open={open} onClose={onClose}>
            <DialogContent>
              <Box
                component="img"
                alt="Imagen ampliada"
                src={imageUrl}
                sx={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: 1.5,
                  cursor: 'pointer'
                }}
                onClick={onClose}
              />
            </DialogContent>
          </Dialog>
        );
    };

    function renderColumnStatus(data: MemorandumDetalleTableModel): ReactElement {
        const color = 'white';
        const background = data.activo? ESTADO_A[0] : ESTADO_A[1];
        const estado = data.activo?'ACTIVO':'INACTIVO';
        return <StatusColumn status={estado} color={color} background={background} />;
    }

    function renderColumnPadre(data: MemorandumDetalleTableModel): ReactElement {
        // se cambia lo de padre por activo pero se debe verificar
        const padre = data.activo?'SI':'NO';
        return <StatusColumn status={padre} background={BACKGROUND_1}/>;
    }

    return (
        <Paper>
            <Box
                sx={{
                    backgroundColor: 'primary.lighter',
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    color: 'black',
                    px: 2,
                    py: 1
                }}
            >
            {
                data?<Typography variant = "body2" component = "span" sx = {{ color: 'black'}} >
                        <Typography variant = "body2" component = "span" sx = {{ color: 'info.main', fontWeight: 'bold' }}>Fecha Registro Memorandum:&nbsp;</Typography>{format(new Date(data.fecha_memo_registro), FORMAT).toString()}
                        <br></br>
                        <Typography variant = "body2" component = "span" sx = {{ color: 'info.main', fontWeight: 'bold'}}>Nombre Memorandum:&nbsp;</Typography>{data.nombre_usuario}
                        <Typography variant = "body2" component = "span" sx = {{ pl: 4, color: 'info.main', fontWeight: 'bold' }}>C.I.:&nbsp;</Typography>{data.ci}
                        <Typography variant = "body2" component = "span" sx = {{ pl: 4, color: 'info.main', fontWeight: 'bold' }}>Cargo Usuario:&nbsp;</Typography>{data.cargo_usuario}
                        <br></br>
                        <Typography variant = "body2" component = "span" sx = {{ color: 'info.main', fontWeight: 'bold'}}>Codigo:&nbsp;</Typography>{data.cod_depart_memo}
                        <Typography variant = "body2" component = "span" sx = {{ pl: 4, color: 'info.main', fontWeight: 'bold' }}>Cantidad de dias:&nbsp;</Typography>{data.cantidad_dias}
                        <Typography variant = "body2" component = "span" sx = {{ pl: 4, color: 'info.main', fontWeight: 'bold' }}>Fecha Inicio:&nbsp;</Typography>{format(new Date(data.fecha_inicio_viaje), FORMAT).toString()}
                        <Typography variant = "body2" component = "span" sx = {{ pl: 4, color: 'info.main', fontWeight: 'bold' }}>Fecha Final:&nbsp;</Typography>{format(new Date(data.fecha_fin_viaje), FORMAT).toString()}
                    </Typography>
                    :<Typography variant="subtitle1" component="span">Cargando...</Typography>
            }
            </Box>
            {RenderViewDialog({ open, onClose: handleClose, imageUrl: getAvatarURL(avatar) })}
            <DataTable
               ref={tableRef}
               headers={tableHeaders}
               updateParams={tableParams}
               onUpdate={handleUpdateTable}
               onActionAddClick={onAddClick}
               vScroll
            />
        </Paper>
    );
};

export const MemorandumDetalleTable = forwardRef(MemorandumDetalleTableComponent);
