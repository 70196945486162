import React, { ReactElement, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui

// components
import { ProcesoTable, ProcesoTableModel, ProcesoTableRefProps } from './components/ProcesoTable';
import ProcesoDialog from './components/ProcesoDialog';
//services
import { useNotify } from 'services/notify';
import { ProcesoFormModel, ProcesoModuleService } from './ProcesoModuleService';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';

import { RUTAS } from 'constants/routes';

export const ProcesoModule = (): ReactElement => {
    const navigate = useNavigate();
    const notify = useNotify();
    const isMounted = useIsMounted();

    const [loading, setLoading] = useState<string>("");

    const [openDeclaracionForm, setOpenDeclaracionForm] = useState(false);
    const [formModel, setFormModel] = useState<ProcesoFormModel>();

    const tableRef = useRef<ProcesoTableRefProps>(null);

    const handleClickView = async (id_actividad: string) => {
        const actividadFormResponse = await ProcesoModuleService.getProcesoFormData(id_actividad);
        if (!actividadFormResponse.success) return notify.error(actividadFormResponse.msg);
        const newFormModel = actividadFormResponse.data;
        setFormModel(newFormModel);
        setOpenDeclaracionForm(true);
    };

    const handleClickImprimir = async (data: ProcesoTableModel) => {
        if (loading) return;
        if (!isMounted()) return
        setLoading(data.id);
        return ProcesoModuleService.getReportPDF(data.id, data.codigo_interno_entidad).then(() => {
            setLoading("");
        });
    };

    return (
        <>
            <ProcesoDialog
                open={openDeclaracionForm}
                onComplete={() => {
                    setOpenDeclaracionForm(false);
                }}
                formModel={formModel}
            />
            <ProcesoTable
                ref={tableRef}
                onViewClick={handleClickView}
                onDetalleClick={(idProceso) => navigate({
                    pathname: RUTAS.proceso_detalle.getPath({ id: idProceso})
                })}
                onImprimirClick={handleClickImprimir}
                loading={loading}
            />
        </>
    );
};
