import React, { ReactElement, useState, useRef, useEffect } from 'react';
// @mui

// components

import { useNotify } from 'services/notify';

import {useParams} from 'react-router-dom';
import { MemorandumFormModel } from '../memorandum/components/MemorandumFormDialog';
import { useIsMounted } from 'hooks/useIsMounted';
import { MemorandumModuleService } from '../memorandum/MemorandumModuleService';
import { MemorandumDetalleFormDialog, MemorandumDetalleFormModel } from './components/MemorandumDetalleFormDialog';
import { MemorandumDetalleTable, MemorandumDetalleTableRefProps } from './components/MemorandumDetalleTable';


export const MemorandumDetalleModule = (): ReactElement => {
    const notify = useNotify();
    const params = useParams();
    const ID_MEMORANDUM = params.id || '';
    const [memorandumData, setMemorandumData] = useState<MemorandumFormModel>();
    const isMounted = useIsMounted();


    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [openDetalleDestinoForm, setOpenDetalleDestinoForm] = useState(false);
    const [formModel, setFormModel] = useState<MemorandumDetalleFormModel>();

    const handleClickView = async (id_area: string) => {
        const actividadFormResponse = await MemorandumModuleService.getDetalleDestinoFormData(id_area);
        if (!actividadFormResponse.success) return notify.error(actividadFormResponse.msg);
        const newFormModel = actividadFormResponse.data;
        setFormModel(newFormModel);
        setOpenDetalleDestinoForm(true);
    };

    const handleClickAdd = async () => {
        setFormModel(undefined);
        setFormOpen(true);
    };

    const handleClickEdit = async (id_area: string) => {
        const actividadFormResponse = await MemorandumModuleService.getDetalleDestinoFormData(id_area);
        if (!actividadFormResponse.success) return notify.error(actividadFormResponse.msg);
        const newFormModel = actividadFormResponse.data;
        setFormModel(newFormModel);
        setFormOpen(true);
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            const memorandumDetalleFormResponse = await MemorandumModuleService.getMemorandumFormData(ID_MEMORANDUM);
            if (!memorandumDetalleFormResponse.success) return notify.error(memorandumDetalleFormResponse.msg);
            const newFormModel: MemorandumFormModel | null = memorandumDetalleFormResponse.data || null;
            if (isMounted()) {
                if (newFormModel !== null)
                    setMemorandumData({
                                    //Tabla original de meorandum
                                       cod_depart_memo        : newFormModel.cod_depart_memo,
                                        autorizado_por         : newFormModel.autorizado_por,
                                    // cargo_jefe_unidad      : string;
                                        fecha_memo_registro    : newFormModel.fecha_memo_registro,
                                        tipo_comision_idp      : newFormModel.tipo_comision_idp,
                                        fecha_inicio_viaje     : newFormModel.fecha_inicio_viaje,
                                        fecha_fin_viaje        : newFormModel.fecha_fin_viaje,
                                        cantidad_dias          : newFormModel.cantidad_dias,
                                        tipo_memo_repo         : newFormModel.tipo_memo_repo,
                                        tipo_transporte        : newFormModel.tipo_transporte,
                                        observacion            : newFormModel.observacion,
                                        estado_memorandum      : newFormModel.estado_memorandum,
                                        notificacion_memo      : newFormModel.notificacion_memo,
                                        apertura_viatico_id   : newFormModel.apertura_viatico_id,
                                        usuario_id            : newFormModel.usuario_id,
                                        vehiculo_id           : newFormModel.vehiculo_id,
                                        cargo_id              : newFormModel.cargo_id,

                                        nombre_usuario : newFormModel.nombre_usuario,
                                        ci: newFormModel.ci,
                                        cargo_usuario : newFormModel.cargo_usuario,
                                        nume_celular:newFormModel.nume_celular

                                    });

            }
        };
        if(ID_MEMORANDUM) fetchData();

    }, [ID_MEMORANDUM]);


    const tableRef = useRef<MemorandumDetalleTableRefProps>(null);

    return (
        <>
            <MemorandumDetalleFormDialog
                open={formOpen}
                formModel={formModel}
                onComplete={() => {
                    setFormOpen(false);
                    tableRef.current?.refresh();
                }}
                memorandumId={ID_MEMORANDUM}

            />
            <MemorandumDetalleTable
                ref={tableRef}
                onViewClick={handleClickView}
                onAddClick={handleClickAdd}
                onEditClick={handleClickEdit}
                memorandumId={ID_MEMORANDUM}
                data={memorandumData || null}
            />
        </>
    );
};
