import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, OnUpdateOptions, DataTableRefProps } from 'components/core/DataTable';
//@mui
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Block from '@mui/icons-material/Block';
//services
import { QueryParams } from 'services/base/Types';
import { useNotify } from 'services/notify';
//model
import { ProcesoModuleService } from 'modules/contra/proceso/ProcesoModuleService';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';

import { getAvatarURL } from 'utils';
import { ProcesoTableItem } from './ProcesoTableItem';


export type ProcesoTableModel = {
    id    : string;
    hoja_ruta    : string;
    modalidad_descripcion : string;
    codigo_interno_entidad: string;
    cuce                  : string;
    objeto_contratacion   : string;
    solicitante           : string;
    area                  : string;
    imagen                : string;

    fecha_registro        : string;
    gestion               : string;
    estado                : string;
    estado_activo         : boolean;

    // para las columnas especiales
    actions: unknown;
    options?: unknown;
};


export type ProcesoTableRefProps = {
    refresh: (updateParams?: UpdateParams<ProcesoTableModel>) => void;
    getQueryParams: () => QueryParams;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<ProcesoTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

const entidadConvocanteFilter: HeaderFilter = { type: 'text' };
const cuceFilter: HeaderFilter = { type: 'text' };
const codigoFilter: HeaderFilter = { type: 'text' };
const objetoContratacionFilter: HeaderFilter = { type: 'text' };

type Props = {
    onDetalleClick: (idProceso: string) => void;
    onViewClick: (idProceso: string) => void;
    onImprimirClick: (data: ProcesoTableModel) => void;
    loading: string;
};

export const ProcesoTableComponent = (props: Props, ref: React.Ref<ProcesoTableRefProps>): ReactElement => {
    const { onDetalleClick, onViewClick, loading, onImprimirClick } = props;

    const notify = useNotify();
    const isMounted = useIsMounted();
    const [tableParams, setTableParams] = useState<UpdateParams<ProcesoTableModel>>(tableParamsInitialize);

    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<ProcesoTableModel>[] = [
        { id: 'objeto_contratacion', label: 'Objeto de la Contratacion', align: 'left', width: 350, filter: objetoContratacionFilter },
        { id: 'cuce', label: 'CUCE', align: 'left', filter: cuceFilter, width: 180 },
        { id: 'modalidad_descripcion', label: 'Modalidad de contratacion', align: 'left' },
        { id: 'area', label: 'Entidad Convocante', align: 'left', filter: entidadConvocanteFilter, width: 350 },
        { id: 'codigo_interno_entidad', label: 'Codigo Interno', align: 'left', filter: codigoFilter, width: 180 },
        { id: 'solicitante', label: 'Responsable solicitante', align: 'left', render: renderColumnSolicitante, width: 250 },
        { id: 'fecha_registro', label: 'Fecha de Registro', align: 'left', width: 150 },
        {
            id: 'options',
            label: 'Opciones',
            sort: false,
            render: renderColumnOptions
        },
    ];

    const handleUpdateTable = (params: UpdateParams<ProcesoTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        ProcesoModuleService.getTableProceso(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<ProcesoTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    function renderColumnSolicitante(tableModel: ProcesoTableModel): ReactElement {
        const imagen = tableModel.imagen;
        return  (
            <Box display="flex" flexDirection="row" alignContent="space-evenly" >
                <Box
                    component="img"
                    alt={tableModel.solicitante}
                    src={getAvatarURL(imagen)}
                    sx={{ width: 32, height: 32, borderRadius: 1.2, flexShrink: 0, mr: 1 }}
                />
                <Typography variant="caption">{tableModel.solicitante}</Typography>
            </Box>
        );
    }

    function renderColumnOptions(data: ProcesoTableModel): ReactElement {
        if(data.estado_activo===false) return(
                                                <IconButton size="small">
                                                    <Block />
                                                </IconButton>
                                            );
        return (
            <>
                <IconButton size="small" onClick={() => onDetalleClick(data.id)} color="info">
                    <ListAltIcon />
                </IconButton>
                <IconButton size="small" disabled={loading===data.id} onClick={() => onImprimirClick(data)} color="error">
                    {loading===data.id ? <CircularProgress size={16}  />:<PictureAsPdfIcon />}
                </IconButton>
            </>
        );
    }

    function renderMobileComponent(data: ProcesoTableModel) {
        return (
            <ProcesoTableItem
                data={data}
                refresh={() => tableRef.current?.refresh()}
                onViewClick={() => onViewClick(data.id)}
                onDetalleClick={() => onDetalleClick(data.id)}
                onImprimirClick={() => onImprimirClick(data)}
                loading={loading}
            />
        );
    }

    return (
        <>
            <DataTable
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                mobileComponent={renderMobileComponent}
                vScroll
            />
        </>
    );
};

export const ProcesoTable = forwardRef(ProcesoTableComponent);
