import { BaseService } from 'services/base/BaseService';
import { BaseResponse, QueryParams } from 'services/base/Types';
import { ProcesoTableModel } from './components/ProcesoTable';
import { getFileName } from 'utils';

export type ProcesoFormModel = {
    id                    ?: number;
    objeto_contratacion    : string;
    modalidad_descripcion  : string;
    modalidad_sigla        : string;
    codigo_interno_entidad : string;
    cuce                   : string;
    fecha_registro         : Date;
    gestion                : string;
    usuario_solicitante_id?: string;
    area_id               ?: string;

    _createdBy?: string;
    _createdAt?: string;
    _updatedBy?: string;
    _updatedAt?: string;
};

export type ProcesoDetalleTableModel = {
    id    : string;
    titulo      : string;
    subtitulo   : string;
    descripcion : string;
    tiempo      : string;
    notificacion: boolean;
    observacion : string;
    fecha       : Date;
    imagen      : string;
    estado      : string;

    usuario_id  : string;
    proceso_id  : string;
};

type ActividadFormModel = {
    id                      ?: number;
    titulo                   : string;
    descripcion              : string;
    paso                     : number;
    tiempo                   : string;
    notificacion             : boolean;
    notificacion_solicitante?: boolean;
    observacion              : string;
    estado                   : string;
    fecha                    : Date;
    fecha_limite             : Date;
    fecha_envio?             : Date;
    usuario_id?              : string;
    proceso_id?              : string;
};

const getTableProceso = async (queryParams?: QueryParams): Promise<BaseResponse<ProcesoTableModel>> => {
    return BaseService.findAll<ProcesoTableModel>('/proceso/proceso_table', queryParams);
};

const getProcesoFormData = async (id: string): Promise<BaseResponse<ProcesoFormModel>> => {
    return BaseService.request('get', `/proceso/proceso_form/${id}`);
};

const getTableProcesoDetalle = async (id_proceso: string, queryParams?: QueryParams): Promise<BaseResponse<ProcesoDetalleTableModel>> => {
    return BaseService.findAll<ProcesoDetalleTableModel>(`/proceso/proceso_table_timeline/${id_proceso}`, queryParams);
};

const getAllProceso = async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; concepto: string }>> => {
    return BaseService.findAll('/proceso/procesos', queryParams);
};

const getActividadFormData = async (id: string): Promise<BaseResponse<ActividadFormModel>> => {
    return BaseService.request('get', `/actividad/actividad_form/${id}`);
};

const getReportPDF = async (id: string, cod: string): Promise<BaseResponse<unknown>> => {
    const filename = getFileName('ProcesoReporte',`${cod}`);
    const result = await  BaseService.download('post', `/proceso/pdf_proceso_detalle_reporte`, { id }, filename);
    if (!result.success) {
        return BaseService.sendError({ msg: result.msg });
    }
    return BaseService.sendSuccess();
};

export const ProcesoModuleService = {
    getTableProceso,
    getReportPDF,
    getProcesoFormData,
    getActividadFormData,
    getTableProcesoDetalle,
    getAllProceso
};
