import React, { useState, useMemo } from 'react';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack, Divider } from '@mui/material';
import packageJson from '../../../package.json';
import Badge from '@mui/material/Badge';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import useLocalStorage from 'hooks/localstorage/useLocalStorage';
//
import navConfig from './NavConfig';
import { DEFAULT_NOTIFICATION_ALERT, NotificationAlert } from 'services/firebase/firebase';
import { useSession } from 'hooks/session';
import { truncateTextLong } from 'utils/formatText';
import { useNotify } from 'services/notify';
import { CacheBuster } from 'CacheBuster';
import { STORAGE_LOCAL } from 'constants/enums';
import { STORAGE_URL, WEB_TITLE_APP } from 'config/app-config';
// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        width: DRAWER_WIDTH
    }
}));

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 1),
}));

// ----------------------------------------------------------------------

type Props = {
    isOpenSidebar: boolean;
    onCloseSidebar: () => void;
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }: Props) {
    const { pathname } = useLocation();
    const authUser = useSession();
    const notify = useNotify();
    const [updatingVersion, setUpdatingVersion] = useState<boolean>(false);
    const isDesktop = useResponsive('up', 'lg');
    const getAvatarURL = (avatar: string) => avatar && avatar.endsWith('.jpg')? `${STORAGE_URL}/${avatar}`: `${STORAGE_LOCAL}/${avatar}`;

    const imagen_avatar = getAvatarURL(authUser?.avatar);
    //console.log("🚀 ~ DashboardSidebar ~ imagen_avatar:", imagen_avatar)

    const [notificationAlert] = useLocalStorage<NotificationAlert>('notification_alert', DEFAULT_NOTIFICATION_ALERT);

    //Menu
    const [dashboardGrid] = useLocalStorage<any>('dashboardMenu', []);
    const rutasAuth = dashboardGrid as Array<any>;
    const menuAuth = navConfig(rutasAuth);

    const hasNotificationAlert = (): boolean => {
        return !notificationAlert.isLatestVersion;
    };

    const handleUpdateVersionClick = async () => {
        if (updatingVersion) return;
        setUpdatingVersion(true);
        const updated = await CacheBuster.updateToLastVersion();
        if (!updated) {
            setTimeout(() => {
                setUpdatingVersion(false);
                notify.info('La última versión ya esta instalada.');
            }, 2000);
        }
    };

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar();
        }
    }, [pathname]);

    const renderContent = (isDraw: boolean) => (
        <Scrollbar
            sx={{
                backgroundImage: `url("/static/images/fondo2.jpg")`,
                backgroundPosition: 'bottom center',
                backgroundSize: 'contain',
                backgroundRepeat  : 'no-repeat',
                overflow: 'hidden',
                height: 1,
                '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
            }}
        >
            <Box
                sx={{
                    px: 1.5,
                    py: 3,
                    display: 'inline-flex',
                    backgroundColor: 'error.main',
                }}>
                <Badge color="error" variant="dot" invisible={!hasNotificationAlert()}>
                    <Logo title={WEB_TITLE_APP} isDraw={isDraw} colorText='common.white' sx={{ width: 90, height: 90 }} />
                </Badge>
            </Box>
            <Box
                sx={{
                    backgroundImage: (theme: any) => `linear-gradient(135deg, ${alpha(theme.palette['error'].main, 0)} 0%, ${alpha(theme.palette['error'].main, 0.34)} 100%)`
                }}>
                <AccountStyle>
                    <Avatar src={imagen_avatar} alt="image_avatar" />
                    <Box sx={{ ml: 1 }}>
                        <Typography variant="caption" sx={{ color: 'text.primary' }}>
                            {truncateTextLong(authUser.username, 26)}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {authUser.roles}
                        </Typography>
                    </Box>
                </AccountStyle>
            </Box>
            <Divider />
            <NavSection navConfig={menuAuth} />
            <Box sx={{ flexGrow: 1, overflow: 'auto' }} />
            <Box sx={{
                px: 2.5,
                pb: 3,
                position: 'absolute',
                right: 0,
                bottom: 0
             }}>
                <Stack alignItems="end" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
                    {!notificationAlert.isLatestVersion && (
                        <>
                            <Box component="img" src="/static/images/version.png" sx={{ width: 80, position: 'absolute', top: -50 }} />
                            <Button onClick={() => handleUpdateVersionClick()} variant="contained">
                                {updatingVersion ? 'Actualizando ...' : 'Actualizar versión'}
                            </Button>
                        </>
                    )}
                    {notificationAlert.isLatestVersion && (
                        <Typography variant="caption" sx={{ color: 'text.primary' }}>
                            Versión {packageJson.version}-rc
                        </Typography>
                    )}
                </Stack>
            </Box>
        </Scrollbar>
    );

    return (
        <RootStyle>
            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH }
                    }}
                >
                    {renderContent(!isDesktop)}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant="persistent"
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.primary',
                            borderRightStyle: 'dashed'
                        }
                    }}
                >
                    {renderContent(true)}
                </Drawer>
            )}
        </RootStyle>
    );
}
