import React, { ReactElement } from 'react';

// @mui
import { Button, Box, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';

// components
import { DataSearch } from 'components/core/DataSearch';
import { ActionBarProps, TableHeader, MyTableModel } from './Types';

export const ActionBar = ({
    updateParams,
    headers,
    loading,
    onFilterClick,
    onResfreshClick,
    onSearchClick,
    onSearchTextChange,
    onActionAddClick,
    onDownloadClick
}: ActionBarProps): ReactElement => {

    const hasHeaderFilters = headers.filter((column: TableHeader<MyTableModel>) => column.filter).length > 0;
    const theme = useTheme();
    const direccion = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Box
            sx={{
                display: direccion ? 'flex' : 'inline',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                my: 2
            }}
        >
            <Box component="span">
                {onActionAddClick && (
                    <Button variant="contained" color="secondary" disableElevation onClick={onActionAddClick} size="small" sx={{ mx: 1 }}>
                        <AddIcon />
                        Agregar
                    </Button>
                )}
                {onFilterClick && (
                    <Button variant="contained" onClick={onFilterClick} disabled={!hasHeaderFilters} disableElevation size="small" sx={{ mx: 1 }}>
                        <FilterListIcon />
                    </Button>
                )}
                {onResfreshClick && (
                    <Button variant="contained" disableElevation onClick={onResfreshClick} size="small" sx={{ mx: 1 }}>
                        <RefreshIcon />
                    </Button>
                )}
            </Box>
            <Box
                display='flex'
                justifyContent='space-between'
            >
                {onDownloadClick && (
                    <Button disabled={loading} variant="contained" onClick={onDownloadClick} sx={{ mx: 1, alignSelf: 'center' }} size="small" color="error">
                        {loading ? <CircularProgress size={24} style={{ color: 'white' }} />:<PdfIcon />}
                    </Button>
                )}
                {onSearchClick && <DataSearch updateParams={updateParams} onSearchClick={onSearchClick} onSearchTextChange={onSearchTextChange} />}
            </Box>
        </Box>
    );
};
