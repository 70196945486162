export const HOME = '/';
export const NOT_FOUND = '*';
export const FORGOT = '/forgot';
export const RESET = '/reset';
export const LOGIN = '/login';
export const REGISTER = '/register';
/* rutas privadas */
export const DASHBOARD = '/dashboard';
export const DASHBOARD_NOTIFICACION = '/dashboard/notificacion';

// RRHH
export const RRHH = '/rrhh';
export const RRHH_SOLICITUD_VACACION = '/rrhh/solicitud_vacacion';
export const RRHH_DECLARACION = '/rrhh/declaracion';
export const RRHH_ASISTENCIA = '/rrhh/asistencia';
export const RRHH_FICHA = '/rrhh/ficha';
export const RRHH_FICHA_ADMIN = '/rrhh/ficha_admin';
export const RRHH_PERMISO = '/rrhh/permiso';

//BSSS
export const BSSS = '/bienes';
export const BSSS_VALE = '/bienes/vale';

//CONTRA
export const CONTRA = '/contra';
export const CONTRA_PROCESO = '/contra/proceso';
export const CONTRA_PROCESO_DETALLE = '/contra/proceso/:id';

//SIGAPO
export const GABI = '/gabi';
export const GABI_SIGAPO = '/gabi/sigapo';
export const GABI_SIGAPO_DETALLE = '/gabi/sigapo_detalle/:id';

//VIATICO
export const VIATICO = '/viatico';
export const VIATICO_MEMORANDUM = '/viatico/memorandum';
export const VIATICO_MEMORANDUM_DETALLE = '/viatico/memorandum/:id';

const buildPath = (path: string, params: { [key: string]: string }) => {
    let result = path;
    Object.keys(params).forEach(param => result = result.replace(`:${param}`, encodeURIComponent(params[param])));
    return result;
};

export type Params = { id: string; };

const getProcesoDetallePath    = (params: Params) => buildPath(CONTRA_PROCESO_DETALLE, params);
const getSigapoDetallePath     = (params: Params) => buildPath(GABI_SIGAPO_DETALLE, params);
const getDetalleMemorandumPath = (params: Params) => buildPath(VIATICO_MEMORANDUM_DETALLE,params);

/* export const MENU_NAV = [
    { value: 'panel', title: 'Panel', path: DASHBOARD, icon: 'material-symbols:dashboard' },
    { value: 'solicitud_vacacion', title: 'Solicitud Vacacion', path: RRHH_SOLICITUD_VACACION, icon: 'healthicons:health-worker-form' },
    { value: 'declaracion', title: 'Declaracion', path: RRHH_DECLARACION, icon: 'healthicons:health-worker-form' },
    { value: 'asistencia', title: 'Asistencia', path: RRHH_ASISTENCIA, icon: 'ant-design:message-filled' },
    { value: 'vale', title: 'Vale', path: BSSS_VALE, icon: 'heroicons:ticket-20-solid' },
    { value: 'notificacion', title: 'Notificaciones', path: DASHBOARD_NOTIFICACION, icon: 'ant-design:message-filled' },
]; */

export const RUTAS = {
    proceso_detalle   : { _path: CONTRA_PROCESO_DETALLE, basePath: '/contra/proceso', getPath: getProcesoDetallePath, label: 'Proceso Detalle' },
    sigapo_detalle    : { _path: GABI_SIGAPO_DETALLE, basePath: '/gabi/ficha_detalle', getPath: getSigapoDetallePath, label: 'Sigapo Detalle' },
    memorandum_detalle: { _path: VIATICO_MEMORANDUM_DETALLE, basePath:'/viatico/memorandum',getPath:getDetalleMemorandumPath, label:'Memorandum Detalle'},
};
