export const COLORS = ['#F4808D', '#EC8FBC', '#B8E4A5', '#CF97C6', '#94A9BC', '#EFEFEF', '#EFEFEF'];
export const BGCOLORS = ['#e6072b', '#D22F86', '#74B220', '#965699', '#003679', '#D9D9D9', '#949292'];
                        //  0           1           2       3           4           5           6
export const ESTADO_A = ['#74B220','#C4CDD5'];
export const ESTADO_B: any = {
    PENDIENTE: '#C4CDD5',
    SUPERIOR: '#FFC107',
    APROBADO: '#74B220',
    RECHAZADO: '#FF4842',
};
export const ESTADO_C: any = {
    PENDIENTE: '#C4CDD5',
    OBSERVADO: '#FFC107',
    MARCADO: '#74B220',
};

export const ESTADO_C1: any = {
    PENDIENTE: '#808080',
    ATENDIDO: '#74B220',
    EN_PROCESO: '#FFC107',
    ATRASADO: '#F20000',
    ATENDIDO_CON_RETRASO: '#3366FF',
};

export const ESTADO_D: any = {
    PENDIENTE: 'grey',
    ATENDIDO: 'success',
    EN_PROCESO: 'warning',
    ATRASADO: 'error',
    ATENDIDO_CON_RETRASO: 'secondary',
};

export const ESTADO_E: any = {
    PENDIENTE: '#919EAB',
    RECHAZADO: '#FFC107',
    APROBADO: '#74B220',
    ANULADO: '#FF4842',
};

export const ESTADO_F: any = {
    PENDIENTE: '#919EAB',
    APROBADO_JEFE: '#965699',
    APROBADO_RRHH: '#74B220',
    RECHAZADO: '#FF4842',
};

export const ESTADO_G: any = {
    'ARCHIVO': '#919EAB',
    'NO PROCESADO': '#FFC107',
    'APROBADO': '#74B220',
    'URGENTE': '#FF4842',
};

export const ESTADO_H: any = {
    'Archivo': '#919EAB',
    'Para su conocimiento': '#FFC107',
    'Preparar Informe': '#003679',
    'Preparar Respuesta': '#D22F86',
    'Procesar': '#74B220',
    'URGENTE': '#e6072b',
};

export const BACKGROUND_1 = '#E3F2FD';
export const NOTIFICATION_COLORS = [
    { categoria: 'circular', value: '#e6072b' },
    { categoria: 'comunicado', value: '#D22F86' },
    { categoria: 'evento', value: '#74B220' },
    { categoria: 'reunion', value: '#965699' }
];
export const DEFAULT_NO_VISTO_COLOR = '#d6f3c9';
