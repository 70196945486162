import React from 'react';

// @mui
import { Container, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// components
import Page from 'components/Page';
import { useNavigate } from 'react-router-dom';
import { ProcesoDetalleModule } from 'modules/contra/procesodetalle';

// ----------------------------------------------------------------------

export default function Proceso() {
    const navigate = useNavigate();
    return (
        <Page title="Proceso Detalle">
            <Container maxWidth="xl">
                    <Typography variant="h4">
                        <Tooltip title="Atras">
                            <IconButton
                                onClick={() => navigate(-1)}
                            >
                                <KeyboardBackspaceIcon />
                            </IconButton>
                        </Tooltip>
                        Procesos de Contratación
                    </Typography>
                <ProcesoDetalleModule />
            </Container>
        </Page>
    );
}
