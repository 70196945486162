import { BaseService } from 'services/base/BaseService';
import { BaseResponse, QueryParams } from 'services/base/Types';
import { MemorandumTableModel } from './components/MemorandumPropiaTable';
import { MemorandumFormModel } from './components/MemorandumFormDialog';

import { getFileName } from 'utils';
import { MemorandumDetalleFormModel, MemorandumTipoPCPModel } from './components/MemorandumDetalleFormDialog';
import { DetalleDestinoTableModel } from './components/MemorandumDetalleTable';

const getTableMemorandumPropio = async (queryParams?: QueryParams): Promise<BaseResponse<MemorandumTableModel>> => {
    return BaseService.findAll<MemorandumTableModel>('/memorandum/memorandum_table', queryParams);
};

const getTablePermisoSolicitada = async (queryParams?: QueryParams): Promise<BaseResponse<MemorandumTableModel>> => {
    return BaseService.findAll<MemorandumTableModel>('/memorandum/permiso_table_solicitada', queryParams);
};

const createOrUpdateMemorandum = async (data: MemorandumFormModel): Promise<BaseResponse<unknown>> => {
    return BaseService.request('post', `/memorandum/memorandum_form`, data);
};

const getMemorandumFormData = async (id: string): Promise<BaseResponse<MemorandumFormModel>> => {
    return BaseService.request('get', `/memorandum/memorandum_form/${id}`);
};

const destroyMemorandum = async (id: string): Promise<BaseResponse<unknown>> => {
    return BaseService.request('delete', `/memorandum/memorandum_table/${id}`);
};

const getAllArea = async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; }>> => {
    return BaseService.findAll('/area/areas', queryParams);
};

const getAllUsuarios = async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; }>> => {
    return BaseService.findAll('/users/usuarios_area', queryParams);
};

const getReportMemorandumPDF = async (id: string, cod: string): Promise<BaseResponse<unknown>> => {
    const filename = getFileName('MemorandumReporte',`${cod}`);
    const result = await  BaseService.download('post', `/memorandum/pdf_reporte`, { id }, filename);
    if (!result.success) {
        return BaseService.sendError({ msg: result.msg });
    }
    return BaseService.sendSuccess();
};

const getAllAperturaViaticoByUser = async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; }>> => {
    return BaseService.findAll('/apertura_viatico/apertura_viatico_usuario', queryParams);
};

const getAllVehiculos = async (params?: any): Promise<BaseResponse<any>> => {
    return BaseService.findAll(`/vehiculo/vehiculos/${params.id}`);
};

const getTipoPCP = async (id: string): Promise<BaseResponse<MemorandumTipoPCPModel>> => {
    return BaseService.request('get', `/memorandum/memorandum_tipo_pcp/${id}`);
};

const getAllPaises= async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; }>> => {
    return BaseService.findAll('/escala_destino/escala_destino_pais', queryParams);
};

const getAllComunidades= async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; }>> => {
    return BaseService.findAll('/escala_destino/escala_destino_comunidad', queryParams);
};

const getRangoFechas = async (memorandum_id: string,): Promise<BaseResponse<{ id: Date; nombre: string; }>> => {
    return BaseService.findAll(`/detalle_destino/detalle_destino_rango_fechas/${memorandum_id}`);
};

const getDetalleDestinoFormData = async (id: string): Promise<BaseResponse<MemorandumDetalleFormModel>> => {
    return BaseService.request('get', `/detalle_destino/detalle_destino_form/${id}`);
};

const getTableDetalleDestino = async (queryParams?: QueryParams): Promise<BaseResponse<DetalleDestinoTableModel>> => {
    return BaseService.findAll<DetalleDestinoTableModel>('/detalle_destino/detalle_destino_table', queryParams);
};

const destroyDetalleDestino = async (id: string): Promise<BaseResponse<unknown>> => {
    return BaseService.request('delete', `/detalle_destino/detalle_destino_table/${id}`);
};

export const MemorandumModuleService = {
    getTableMemorandumPropio,
    getTablePermisoSolicitada,
    getTableDetalleDestino,
    createOrUpdateMemorandum,
    getMemorandumFormData,
    getAllVehiculos,
    getAllArea,
    getAllAperturaViaticoByUser,
    getTipoPCP,
    getAllPaises,
    getRangoFechas,
    getAllComunidades,
    getReportMemorandumPDF,
    getAllUsuarios,
    getDetalleDestinoFormData,
    destroyDetalleDestino,
    destroyMemorandum
};
