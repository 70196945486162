import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { FormGroup, FormDialog, FormValue, SelectOption } from 'components/core/FormDialog';
import { MemorandumModuleService } from '../MemorandumModuleService';
import { useNotify } from 'services/notify';
import { Options2FormModel, OptionsFormModel } from 'modules/Types';
import { useIsMounted } from 'hooks/useIsMounted';
import { ENUM_TIPO_COMISION, ENUM_TIPO_MEMORANDUM, ENUM_TIPO_TRANSPORTE, TIME_FORMAT_2 } from 'constants/enums';
import { afterDateTest, dateTest } from 'components/core/FormDialog/yup-tests';
import { useSession } from 'hooks/session';

export type MemorandumFormModel = {
    id?                : string;
    cod_depart_memo    : string;
    autorizado_por     : string;
    fecha_memo_registro: Date;
    tipo_comision_idp  : string;
    fecha_inicio_viaje : Date;
    fecha_fin_viaje    : Date;
    cantidad_dias      : number;
    tipo_memo_repo     : string;
    tipo_transporte    : string;
    observacion        : string;
    estado_memorandum  : string;
    notificacion_memo  : string;
    apertura_viatico_id: string | null;
    usuario_id         : string | null;
    vehiculo_id        : string | null;
    cargo_id           : string | null;
    nombre_usuario     : string;
    ci                 : string;
    cargo_usuario      : string
    nume_celular       : string;
};

type Props = {
    open: boolean;
    formModel?: MemorandumFormModel;
    onComplete: () => void;
};

const FORMAT = 'dd/MM/yyyy';

export const MemorandumFormDialog = ({ open, formModel, onComplete }: Props): ReactElement => {
    const notify = useNotify();
    const isMounted = useIsMounted();
    const authUser = useSession();

    const comisionOptions: SelectOption[] = ENUM_TIPO_COMISION;
    const tipoMemoOptions: SelectOption[] = ENUM_TIPO_MEMORANDUM;
    const transporteOptions: SelectOption[] = ENUM_TIPO_TRANSPORTE;

    const [usuarios, setUsuarios] = useState<OptionsFormModel[]>([]);
    const usuariosOptions: SelectOption[] = usuarios.map((item: OptionsFormModel) => ({ value: item.id || '', label: item.nombre }));

    const [aperturaViaticos, setAperturaViaticos] = useState<Options2FormModel[]>([]);
    const aperturaViaticosOptions: SelectOption[] = aperturaViaticos.map((item: Options2FormModel) => ({ value: item.id || '', label: item.nombre }));

    const formLayout: FormGroup<MemorandumFormModel>[] = [
        {
            title: 'Datos Usuario Memorandum',
            grid: [
                [{ label: `${authUser.nombre}` ,  type: 'label' },],
                [{ name: 'apertura_viatico_id', label:'Apertura Programatica' ,  type: 'autocomplete', options: aperturaViaticosOptions},],
              ]
        },
        {
            title: 'Autorización del Memorandum',
            grid: [
                [{ name: 'cod_depart_memo', label: 'Codificacion Numero de CITE', type: 'text', infoText: 'ej. G.A.D. ORU/SDOP/UNASVI/PROMONSA Nº 032/2024' }],
                [{ name: 'autorizado_por', label: 'Autorizado por', type: 'autocomplete', options: usuariosOptions }],
              ]
        },
        {
            title: 'Datos del Memorandum',
            grid: [
                [
                    { name: 'fecha_memo_registro', label: 'Fecha Registro', type: 'datetime' },
                    { name: 'tipo_comision_idp', label: 'Tipo de Comision', type: 'select', options: comisionOptions },
                ],
                [
                    { name: 'fecha_inicio_viaje', label: 'Fecha Inicio Viaje', type: 'datetime' },
                    { name: 'fecha_fin_viaje', label: 'Fecha Fin Viaje', type: 'datetime' },
                ],
                [
                    { name: 'tipo_memo_repo', label: 'Tipo de Memorandum/Reposicion', type: 'radio-group', options: tipoMemoOptions, inlineDisplay: true }
                ],
                [
                    { name: 'tipo_transporte', label: 'Tipo de Transporte', type: 'select', options:transporteOptions}
                ],
                [   { name: 'observacion', label: 'Observacion', type: 'text', infoText: 'ej. Observaciones  del Viaje' }],
            ]
        },
    ];

    const validationSchema = yup
        .object({
            apertura_viatico_id: yup.string().required(),
            autorizado_por     : yup.string().required(),
            fecha_memo_registro: yup.date().required(),
            tipo_comision_idp  : yup.string().required(),
            fecha_inicio_viaje : yup.date().required(),
            fecha_fin_viaje    : yup.date().required(),
            tipo_memo_repo     : yup.string().required(),
            tipo_transporte    : yup.string().required(),
            observacion        : yup.string().required(),
            cod_depart_memo    : yup.string().required(),
        })
        .defined();

    const handleSubmit = async (formData: FormValue) => {
        const result = await MemorandumModuleService.createOrUpdateMemorandum(formData as unknown as MemorandumFormModel);
        if (!result.success) return notify.error(result.msg);
        notify.success(result.msg);
        return onComplete();
    };

    const handleCancel = () => {
        onComplete();
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            const resultArea = await MemorandumModuleService.getAllArea();
            if (!resultArea || !resultArea.success) return;
            const areas = resultArea.rows || [];

            const resultUsuario = await MemorandumModuleService.getAllUsuarios();
            if (!resultUsuario || !resultUsuario.success) return;
            const newUsuarios = resultUsuario.rows || [];

            const resultAperturaViatico = await MemorandumModuleService.getAllAperturaViaticoByUser();
            if (!resultAperturaViatico || !resultAperturaViatico.success) return;
            const newAperturaViatico = resultAperturaViatico.rows || [];

            if (isMounted()) {
                setUsuarios(newUsuarios);
                setAperturaViaticos(newAperturaViatico);
            };
        };
        if (open) {
            fetchData();
        }
    }, [open, isMounted, formModel]);

    const zeroValues: MemorandumFormModel = {
        nombre_usuario     : authUser.nombre,
        ci                 : '',
        cargo_usuario      : '',
        nume_celular       : '',
        cod_depart_memo    : '',
        autorizado_por     : '',
        fecha_memo_registro: new Date(),
        tipo_comision_idp  : '',
        fecha_inicio_viaje : new Date(),
        fecha_fin_viaje    : new Date(),
        cantidad_dias      : 0,
        tipo_memo_repo     : '',
        tipo_transporte    : '',
        observacion        : '',
        estado_memorandum  : 'PENDIENTE',
        notificacion_memo  : 'SI',
        apertura_viatico_id: '',
        usuario_id         : '',
        vehiculo_id        : '',
        cargo_id           : '',
    };

    const newFormModel = formModel && {
        id                    : formModel.id,
        cod_depart_memo       : formModel.cod_depart_memo,
        autorizado_por        : formModel.autorizado_por,
        fecha_memo_registro   : formModel.fecha_memo_registro,
        tipo_comision_idp     : formModel.tipo_comision_idp,
        fecha_inicio_viaje    : formModel.fecha_inicio_viaje,
        fecha_fin_viaje       : formModel.fecha_fin_viaje,
        cantidad_dias         : formModel.cantidad_dias,
        tipo_memo_repo        : formModel.tipo_memo_repo,
        tipo_transporte       : formModel.tipo_transporte,
        observacion           : formModel.observacion,
        estado_memorandum     : formModel.estado_memorandum,
        notificacion_memo     : formModel.notificacion_memo,
        apertura_viatico_id   : formModel.apertura_viatico_id,
        usuario_id            : formModel.usuario_id,
        vehiculo_id           : formModel.vehiculo_id,
        cargo_id              : formModel.cargo_id
    };

    return (
        <FormDialog
            addTitle="Agregar Memorandum"
            editTitle="Editar Memorandum"
            open={open}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={newFormModel || zeroValues}
            formLayout={formLayout}
            validationSchema={validationSchema}
            isEdit={typeof formModel !== 'undefined'}
        />
    );
};
