import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, ActionColumn, OnUpdateOptions, DataTableRefProps, StatusColumn } from 'components/core/DataTable';
//@mui
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
//services
import { QueryParams } from 'services/base/Types';
import { useNotify } from 'services/notify';
//model
import { MemorandumModuleService } from '../MemorandumModuleService';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';
import { MemorandumPropiaTableItem } from './MemorandumPropiaTableItem';
import { ESTADO_E } from 'constants/colors';
import { getAvatarURL } from 'utils';

export type MemorandumTableModel = {
    id: string;
    cod_depart_memo: string;
    //campos que se aumentan
    usuario_nombre: string;
    usuario_cargo: string;
    ci: string;
    nume_celular: string;
    // campos para apertura
    apertura_viatico: string;
    nombre_area_apertura: string;
    presupuesto_inicial: number;
    presupuesto_restante: number;
    // campos de imagen
    imagen: string;
    imagen2: string;
    //Campos para imprimir
    imprimir: string;
    //campos Memorandum
    autorizado_por: string;
    cargo_jefe_unidad: string;
    fecha_memo_registro: Date;
    vehiculo_id: string; //tipo de vehiculo marca  TV
    num_placa: string; //placa del vehiculo  TV
    tipo_comision_idp: string;
    fecha_inicio_viaje: Date;
    fecha_fin_viaje: Date;
    cantidad_dias: number;
    tipo_memo_repo: boolean;
    tipo_transporte: string;
    observacion: string;
    estado_memorandum: string;
    notificacion_memo: string;
    activo: boolean;

    // para las columnas especiales
    actions: unknown;
    options?: unknown;
};


export type MemorandumPropiaTableRefProps = {
    refresh: (updateParams?: UpdateParams<MemorandumTableModel>) => void;
    getQueryParams: () => QueryParams;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<MemorandumTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

const nombresFilter: HeaderFilter = { type: 'text' };
const nombrePadreFilter: HeaderFilter = { type: 'text' };
const partidaFilter: HeaderFilter = { type: 'text' };

type Props = {
    onAddClick              : () => void;
    onViewClick             : (idPermiso: string) => Promise<void>;
    onEditClick             : (idPermiso: string) => Promise<void>;
    onDetalleMemorandumClick: (idMemorandum: string) => void;
    onImprimirClick         : (data: MemorandumTableModel) => void;  //cambiar areporte
    loading                 : string;
};

export const MemorandumPropiaTableComponent = (props: Props, ref: React.Ref<MemorandumPropiaTableRefProps>): ReactElement => {
    const { onViewClick, onAddClick, onEditClick, onImprimirClick, onDetalleMemorandumClick, loading } = props;
    const notify = useNotify();
    const isMounted = useIsMounted();
    const [tableParams, setTableParams] = useState<UpdateParams<MemorandumTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<MemorandumTableModel>[] = [
        { id: 'actions', label: 'Acciones', sort: false, render: renderColumnActions },
        { id: 'cod_depart_memo', label: 'Codigo Area Memorandum', align: 'center' },
        { id: 'fecha_memo_registro', label: 'Fecha Memorandum', align: 'center', filter: partidaFilter },
        { id: 'autorizado_por', label: 'Autorizado por Jefe de Unidad', align: 'center', render: renderColumnResponsable, width:250},

        { id: 'tipo_comision_idp', label: 'Tipo de Comision', align: 'center', filter: nombrePadreFilter },
        { id: 'fecha_inicio_viaje', label: 'Fecha Inicio Viaje', align: 'center',width:150, filter: nombrePadreFilter },
        { id: 'fecha_fin_viaje', label: 'Fecha Fin Viaje', align: 'center',width:150 },
        { id: 'cantidad_dias', label: 'Cantidad dias', align: 'center', filter: nombresFilter},
        { id: 'tipo_transporte', label: 'Tipo de Transporte', align: 'center', filter: partidaFilter },
        { id: 'notificacion_memo', label: 'Estado', align: 'center', render: renderColumnStatus },//, render: renderColumnPadre
        { id: 'options', label: 'Detalles del Destino', sort: false, render: renderColumnOptions, align: 'center' ,width:150},
        { id: 'imprimir', label: 'Descargar Memorandum', sort: false, render: renderImpresionOptions, align: 'center' ,width:150},
    ];

    function renderColumnStatus(data: MemorandumTableModel): ReactElement {
        const color = 'white';
        const background = ESTADO_E[data.estado_memorandum];
        return <StatusColumn status={data.estado_memorandum} color={color} background={background} />;
    }

    function renderImpresionOptions(data: MemorandumTableModel): ReactElement {
        return (
            <>
                <IconButton size="small" disabled={loading===data.id} onClick={() => onImprimirClick(data)} color="error">
                    {loading===data.id ? <CircularProgress size={16}  />:<PictureAsPdfIcon />}
                </IconButton>
            </>
        );
    }

    function renderColumnOptions(data: MemorandumTableModel): ReactElement {
        return (
            <>
                <IconButton size="small" onClick={() => onDetalleMemorandumClick(data.id)} color="info">
                    <ListAltIcon />
                </IconButton>
            </>
        );
    }

    function renderColumnResponsable(tableModel: MemorandumTableModel): ReactElement {
        const imagen = tableModel.imagen2;
        return  (
            <Box display="flex" flexDirection="row" alignContent="space-evenly" >
                <Box
                    component="img"
                    alt={tableModel.autorizado_por}
                    src={getAvatarURL(imagen)}
                    sx={{ width: 32, height: 32, borderRadius: 1.2, flexShrink: 0, mr: 1 }}
                />
                <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle2">{tableModel.autorizado_por}</Typography>
                    <Typography color="tertiary" variant="caption" sx={{ fontSize: '10px'}}>{tableModel.cargo_jefe_unidad}</Typography>
                </Box>
            </Box>
        );
    }

    const handleUpdateTable = (params: UpdateParams<MemorandumTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        MemorandumModuleService.getTableMemorandumPropio(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<MemorandumTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    function renderMobileComponent(data: MemorandumTableModel) {
        return (
            <MemorandumPropiaTableItem
                data={data}
                refresh={() => tableRef.current?.refresh()}
                onViewClick={() => onViewClick(data.id)}
                onEditClick={() => onEditClick(data.id)}
                onDeleteClick={() => {
                    return MemorandumModuleService.destroyMemorandum(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success(result.msg);
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    function renderColumnActions(data: MemorandumTableModel): ReactElement {
        if(data.estado_memorandum==='APROBADO_SDAFP' || data.estado_memorandum==='RECHAZADO') return(
            <ActionColumn
                onViewClick={() => onViewClick(data.id)}
            />
        );
        return (
            <ActionColumn
                onViewClick={() => onViewClick(data.id)}
                onEditClick={() => onEditClick(data.id)}
                deleteMessage={
                    <div>
                        <div>¿Quiere eliminar el registro?</div>
                        <br />
                        <div>
                            {/*data.sigla y data.nombre <strong>Nombre: </strong> {data.cod_depart_memo +' - '+data.id}*/}
                            <strong>Codigo: </strong> {data.cod_depart_memo}<br></br>
                            <strong>Fecha Creacion Memorandum: </strong> {' - '+data.fecha_memo_registro}<br></br>
                            <strong>Usuario Memorandum: </strong> {' - '+data.usuario_nombre}
                        </div>
                    </div>
                }
                onDeleteClick={async () => {
                    return MemorandumModuleService.destroyMemorandum(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('Memorandum eliminado exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }


    return (
        <>
            <DataTable
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                onActionAddClick={onAddClick}
                mobileComponent={renderMobileComponent}
                vScroll
            />
        </>
    );
};

export const MemorandumPropiaTable = forwardRef(MemorandumPropiaTableComponent);
