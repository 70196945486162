import React, { ReactElement, useState, useRef } from 'react';
// @mui

// components
import { MemorandumFormDialog, MemorandumFormModel } from './components/MemorandumFormDialog';
import MemorandumDialog from './components/MemorandumDialog';
import { SectionItem, SectionNav, SectionNavRefProps } from 'components/core/SectionNav/SectionNav';
import { MemorandumPropiaTable, MemorandumPropiaTableRefProps, MemorandumTableModel } from './components/MemorandumPropiaTable';
import { useIsMounted } from 'hooks/useIsMounted';
//services
import { MemorandumModuleService } from './MemorandumModuleService';
//hook
import { useSession } from 'hooks/session';
import { useNavigate } from 'react-router-dom';
import { useNotify } from 'services/notify';
import { RUTAS } from 'constants/routes';


export const MemorandumModule = (): ReactElement => {
    const notify = useNotify();
    const authUser = useSession();
    const navigate = useNavigate();
    const isMounted = useIsMounted();
    const navRef = useRef<SectionNavRefProps>(null);

    const [loading, setLoading] = useState<string>("");

    const canApprove = authUser.permisos.approve;

    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [openPermisoForm, setOpenPermisoForm] = useState(false);
    const [formModel, setFormModel] = useState<MemorandumFormModel>();

    const handleClickView = async (id_memorandum: string) => {
        const permisoFormResponse = await MemorandumModuleService.getMemorandumFormData(id_memorandum);
        if (!permisoFormResponse.success) return notify.error(permisoFormResponse.msg);
        const newFormModel = permisoFormResponse.data;
        setFormModel(newFormModel);
        setOpenPermisoForm(true);
    };

    const handleClickAdd = async () => {
        setFormModel(undefined);
        setFormOpen(true);
    };

    const handleClickEdit = async (id_memorandum: string) => {
        const permisoFormResponse = await MemorandumModuleService.getMemorandumFormData(id_memorandum);
        if (!permisoFormResponse.success) return notify.error(permisoFormResponse.msg);
        const newFormModel = permisoFormResponse.data;
        setFormModel(newFormModel);
        setFormOpen(true);
    };

    const handleClickImprimir = async (data: MemorandumTableModel) => {
        if (loading) return;
        if (!isMounted()) return
        setLoading(data.id);
        return MemorandumModuleService.getReportMemorandumPDF(data.id, data.cod_depart_memo).then(() => {
            setLoading("");
        });
    };

    const tableRef = useRef<MemorandumPropiaTableRefProps>(null);

    const sections: SectionItem[] = [];

    sections.push({
        id: '1',
        label: 'Propias',
        content: <MemorandumPropiaTable
                    ref={tableRef}
                    onViewClick={handleClickView}
                    onAddClick={handleClickAdd}
                    onEditClick={handleClickEdit}
                    onImprimirClick={handleClickImprimir}
                    onDetalleMemorandumClick={(idMemorandum) => navigate({
                        pathname: RUTAS.memorandum_detalle.getPath({id: idMemorandum})
                    })}
                    loading={loading}
                />
    });

    canApprove && sections.push({
        id: '2',
        label: 'Solicitadas',
        content: <MemorandumPropiaTable
                    ref={tableRef}
                    onViewClick={handleClickView}
                    onAddClick={handleClickAdd}
                    onEditClick={handleClickEdit}
                    onImprimirClick={handleClickImprimir}
                    onDetalleMemorandumClick={(idMemorandum) => navigate({
                        pathname: RUTAS.memorandum_detalle.getPath({id: idMemorandum})
                    })}
                    loading={loading}
                />
    });

    return (
        <>
            <MemorandumFormDialog
                open={formOpen}
                formModel={formModel}
                onComplete={() => {
                    setFormOpen(false);
                    tableRef.current?.refresh();
                }}
            />
            <MemorandumDialog
                open={openPermisoForm}
                onComplete={() => {
                    setOpenPermisoForm(false);
                }}
                formModel={formModel}
            />
                <SectionNav ref={navRef} sections={sections} />
        </>
    );
};
